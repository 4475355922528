<template>
    <v-footer dark padless>
      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong>HG Blocklayers Ltd</strong>
      </v-card-text>
    </v-footer>
</template>
<script>
export default {

};
</script>
<template>
  <v-card class="mt-2">
    <v-card-title>Reviews</v-card-title>
    <v-carousel cycle hide-delimiters :show-arrows="false" height="100%">
      <v-carousel-item
        v-for="(quote, i) in quotes"
        :key="i"
        style="height: auto; margin-left: auto; margin-right: auto"
      >
        <v-sheet tile max-width="800px">
          <v-row color="primary" align="center" justify="center">
            <v-card-text class="max-width:200px">
              <v-icon x-large>mdi-format-quote-open</v-icon
              ><b>{{ quote.quote }} </b>
              <v-icon x-large>mdi-format-quote-close</v-icon></v-card-text
            >
            <v-subheader>- {{ quote.author }}</v-subheader>
            <v-img
              v-if="quote.imageSRC"
              :src="quote.imageSRC"
              class="mb-2"
              max-height="200"
              max-width="200"
            ></v-img>
          </v-row> </v-sheet
      ></v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import anchorImage from "@/assets/Anchor-Homes.webp"
export default {

  data() {
    return {
      quotes: [
        {
          author: "Liam Moreton",
          quote: `The Lads at HG are Vastly Experienced and have a great eye for detail. 
             I employed them for a SELF BUILD project which I managed. 
             Keith , Chris and Johnny always had the site impeccably tidy and would 
             raise concerns to me if issues arose re. the build or materials`,
        },
        {
          author: "Michael Durcan, Director of Knoxpark Developments Ltd",
          quote: `David Harkin is a good and reliable tradesman and block layer. 
            He has done great work for us at Knoxpark Developments for the Farmhill Manor Project. 
            The job was completed very quickly and efficiently. 
            The site was kept neat and tidy during and after. Highly recommended.`,
          imageSRC:
            "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fkdmlogo.png?alt=media&token=93546c1d-8981-460c-99bb-d789da058567",
        },
        {
          author: "Anchor Homes",
          quote: `I would highly recommend David and the lads. 
          Great service, great workmanship. 
          Fantastic team of lads who take a lot of pride in there work. 
          House keeping is top quality. We will certainly be doing business with David and his team Into the future.`,
          imageSRC:anchorImage
        },
      ],
    };
  },
};
</script>
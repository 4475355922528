<template>
  <v-card>
    <div class="hidden-xs-only" >
      <v-card-title>Our Mission</v-card-title>
      <v-card-text class="text--primary">
        "Our mission is to to exceed the expectations of clients, consultants
        and all the Project Team."
      </v-card-text>
    </div>
    <div class="hidden-sm-and-up">
       <v-card-title>Welcome to HG Blocklayers Ltd</v-card-title>
    <v-card-text class="text--primary">
      We specialise in all aspects of masonry works including brickwork,
      block-work and stone cladding. Our team also has expertise in retaining
      walls and shuttering/formwork. We pride ourselves on excellence of craft
      with honesty & integrity to the fore when dealing with both clients and
      other sub-contractors
    </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {};
</script>
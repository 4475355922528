<template>
  <v-container>
    <v-card>
      <v-card-title>Gallery</v-card-title>
      <gallery :images="images" :index="index" @close="index = null"></gallery>
      <v-card-text>
        <v-row>
          <v-col
            v-for="(item, imageIndex) in images"
            :key="imageIndex"
            class="d-flex child-flex"
            cols="6"
            sm="3"
          >
            <v-img
              :src="item"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="index = imageIndex"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import VueGallery from "vue-gallery";

export default {
  metaInfo: {
    title: "Gallery",
  },
  data: function () {
    return {
      index: null,
      images: [
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-front.webp?alt=media&token=0418e2f1-afb3-4f21-b644-acd5c7ec0c60",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-diagnel.webp?alt=media&token=3c79a9f1-ef99-4385-ba56-6065294c458c",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-side.webp?alt=media&token=aceda1f8-741c-4d96-b30c-61b9c2b393d1",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fpath-and-spires.webp?alt=media&token=1a06d837-f1e5-4da2-8839-39c1e0780e8a",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fspire.webp?alt=media&token=50eb888f-1f62-4837-b963-f2c64d7e51e4",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fworker-block-laying-5.webp?alt=media&token=f657f93f-9223-4f9f-953a-060aba298721",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fworker-leveling-2.webp?alt=media&token=2bce04f7-8e98-48b5-b055-f1affd23f0d6",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fworker-skip.webp?alt=media&token=ea241f04-d5c5-465a-a1af-0cfc0633dceb",
    "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fworker-spacing-2.webp?alt=media&token=c96bdd71-ee51-4b1c-b9eb-09d681151aea",
         "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fworkers-planning.webp?alt=media&token=491a4c29-3b78-4632-bcff-8d5f0a8d6891",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fbrick-work1.webp?alt=media&token=0fc94743-55f8-4996-b0f5-1f262b52202c",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fbrick-work2.webp?alt=media&token=12be766b-4ac8-4af2-a02f-a8cd5c37a248",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fbrick-work3.webp?alt=media&token=1ba11a4d-01b5-4dda-a94a-12811dd16ae1",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffresh-concrete-floor.webp?alt=media&token=67f45a91-0d69-45d6-8840-fd2aeef00680",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fchimney.webp?alt=media&token=4082fcd6-4d9b-4ddf-a34b-226485e88672",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-overview-3.webp?alt=media&token=a149864a-349a-4eeb-ae84-2b054b3a622c",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FIMG_1597.webp?alt=media&token=6acb66e3-99ad-404e-a130-412e379c42ca",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffront-new-build-2.webp?alt=media&token=78cba916-811b-487d-b6f5-fe92be7c2f78",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FIMG_1584.webp?alt=media&token=5f8ebae9-6c2a-4081-b87c-092d45d53c51",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffoundation-covered.webp?alt=media&token=a2bb225f-e1a8-440a-8a87-eb43a3a1b352",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffresh-concrete-floor-3.webp?alt=media&token=ace04e3e-f943-4671-bb69-ea93871964db",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffresh-concrete-floor-2.webp?alt=media&token=b05917ec-6da5-47cf-95c0-23146582c8d0",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FIMG_1666.webp?alt=media&token=594740bd-3392-4f0c-91ed-216c14c10dfe",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-far.webp?alt=media&token=6937d2d0-5bb9-4f0f-8b32-46bb61f03ee6",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fstone-work-3.webp?alt=media&token=8d75e8fc-f467-4246-9726-a1815e8ef21b",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fstonework-2.webp?alt=media&token=34c78666-28a5-451c-922f-4207f65ce93e",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-scafold.webp?alt=media&token=6c8b1866-3ddd-4216-8ad3-007b876ae67b",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-overview-2.webp?alt=media&token=efce7f60-0ed5-46e3-b331-0cc8066c0ca9",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-outside-from-lawn.webp?alt=media&token=4080db1c-2184-432d-9d08-dd3f0e22df7f",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-2.webp?alt=media&token=49e3b2e3-8d2f-48cd-919d-8e0c369fbefe",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-and-digger.webp?alt=media&token=0c44e614-f57d-4324-a94f-6075d7f4e9f8",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-1.webp?alt=media&token=a3d9b02f-d0b0-465e-a830-3115b836688d",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fred-brick.webp?alt=media&token=f0c79e77-4ed1-456d-aa50-84a0e67851da",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FIMG_1583.webp?alt=media&token=9655e02a-4c95-49bc-b485-ead842b3026c",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-gable-4.webp?alt=media&token=db1622e8-ae22-4592-8fcf-99a67d4653d8",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-gable-3.webp?alt=media&token=6622bc71-3de4-4b58-836d-b49cadd5f73f",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-gable-1.webp?alt=media&token=01473f7a-5014-4d39-be50-f912d5330901",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-gable-2.webp?alt=media&token=dd348a6d-e4d2-4e42-b15b-cc8fbde27f9b",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FIMG_2552.webp?alt=media&token=864e958d-1011-4328-9119-6104b7b747d7",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffoundation-cover2.webp?alt=media&token=f2854441-6fb7-46a8-9d73-02f7b399ad5e",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffoundation.webp?alt=media&token=ab626fe1-768e-4d57-bca8-451220bc2b8d",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffoundation-side.webp?alt=media&token=8eb148c3-c4fb-4015-b670-e49f23960cdf",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fariel-site2.webp?alt=media&token=fb6602c1-e5b1-4850-876c-9038dd69327f",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fariel-shot.webp?alt=media&token=30faf5c4-da6c-4893-9cb3-58cfef2a7a30",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FIMG_1504.webp?alt=media&token=5e84d895-03d6-46bd-aa7e-2bedf57df300",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FWhatsApp%20Image%202022-01-31%20at%2011.57.40%20PM.webp?alt=media&token=a3a3f8da-3369-4aee-888b-c897cd993e00",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FWhatsApp%20Image%202022-01-31%20at%2011.57.40%20PM(3).webp?alt=media&token=97980bac-8ecc-4b61-9b45-e09cb4762429",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FWhatsApp%20Image%202022-01-31%20at%2011.57.40%20PM(2).webp?alt=media&token=0b193f61-6e45-4618-a017-2c2e7b4cfe4a",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FIMG_1594.webp?alt=media&token=9e919058-7256-44be-b6e7-317d4e2c1f5f",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-worker.webp?alt=media&token=a4c91069-feb6-4658-9c38-3e62fa276f93",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FWhatsApp%20Image%202022-01-31%20at%2011.57.40%20PM(1).webp?alt=media&token=aab894f2-27af-41ac-9a19-fcb913a6402f",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fwindow-blockwork.webp?alt=media&token=f7c738a3-4193-441d-910f-43f3fbae5aee",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FPerspective%20View%20North%20West.webp?alt=media&token=c8a2223d-9e3d-484c-a368-0ab77de08a80",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FPerspective%20View%20South%20West%20.webp?alt=media&token=6a269302-0eef-47c9-8612-81c3c887676c",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FPerspective%20View%20North%20.webp?alt=media&token=ed7b6d63-85c2-4aea-9fab-64dada04e79a",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FHouse%203D.webp?alt=media&token=1e1d4cfa-6656-4092-b11c-842c6bb93242",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-scafold-3.webp?alt=media&token=3724dc77-5360-4dd0-bdcd-0ae8c65fa56d",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Foutsite-concrete.webp?alt=media&token=f955af12-104c-4cc1-964d-621b67353b75",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffront-scafold.webp?alt=media&token=077cdb67-4c76-402f-a640-83c86d5407d8",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Ffront-new-build.webp?alt=media&token=78d0a644-42ad-4ee6-93a7-11f3a75cdcd1",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fstoonework-1.webp?alt=media&token=99573b4b-401b-4755-8b9c-9cfdf5ea91c2",
        "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fsite-overview.webp?alt=media&token=fb7cc88d-8c8a-405a-a0f7-8f9fcf824808",
      ],
    };
  },

  components: {
    gallery: VueGallery,
  },
};
</script> 
 
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style> 
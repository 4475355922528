import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home.vue";
import Projects from "@/views/Projects.vue";
import About from "@/views/AboutUs.vue";
import Gallery from "@/views/Gallery.vue";
import Contact from "@/views/Contact.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/about',
    name: 'About us',
    component: About
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  }
  ,
  {
    path: '/contact',
    name: 'Contact Us',
    component: Contact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const DEFAULT_TITLE = 'HG Blocklayers Limited';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router

<template>
  <v-container>
    <v-card class="mt-20">
      <v-card-title> Contact Details </v-card-title>
      <v-card-text>
        <v-simple-table class="text-left" style="max-width: 35em">
          <tr>
            <td><v-icon class="mr-2"> mdi-phone</v-icon> Phone</td>
            <td>
              <a v-bind:href="'tel:' + phone">{{ phone }}</a>
              </td>
          </tr>
          <tr>
            <td><v-icon class="mr-2"> mdi-email</v-icon> Email</td>
            <td>
              <a v-bind:href="'mailto:' + email">{{ email }}</a>
            </td>
          </tr>
          <tr>
            <td><v-icon class="mr-2"> mdi-clock</v-icon> Opening Hours</td>
            <td>9:00 - 18:30</td>
          </tr>
          <tr>
            <td><v-icon class="mr-2"> mdi-map-marker</v-icon> Location</td>
            <td>Rathbraughan Park, Ballytivnan,</td>
          </tr>
          <tr>
            <td></td>
            <td>Sligo, F91 XP68</td>
          </tr>
        </v-simple-table>
      
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    title: "Contact Us",
  },
  data() {
    return {
      openingHours: "Mon - Fri: 9:00 - 18:30",
      email: "hgblocklayersltd@gmail.com",
      phone: "+353860625481",
    };
  },
};
</script>

<template>
  <div>
    <div class="hidden-xs-only">
      <v-tabs v-model="activeTab"  centered>
        <v-tabs-slider v-model="activeTab"></v-tabs-slider>
        <v-tab
          v-for="tab of tabs"
          :key="tab.id"
          :to="tab.route"
          exact
          @click="navigate(tab.route)"
        >
          {{ tab.name }}
        </v-tab>

        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
        </v-tab-item>
      </v-tabs>
    </div>
    <div class="hidden-sm-and-up justify-center">
      <v-btn icon @click="showMenu = false" v-if="showMenu">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-btn icon @click="showMenu = true" v-else>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-expand-transition>
        <v-tabs
          v-model="activeTab"
          grow
          vertical
          class="d-xs-none"
          v-if="showMenu"
        >
          <v-tabs-slider v-model="activeTab"></v-tabs-slider>
          <v-tab
            v-for="tab of tabs"
            :key="tab.id"
            :to="tab.route"
            exact
            style="width: 100vw;"
            @click="navigate(tab.route)"
          >
            {{ tab.name }}
          </v-tab>

          <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
          </v-tab-item>
        </v-tabs>
      </v-expand-transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: true,
      activeTab: "/about",
      tabs: [
        { id: 0, name: "Home", route: "/" },
        { id: 1, name: "About", route: "/about" },
        { id: 2, name: "Gallery", route: "/gallery" },
        { id: 3, name: "Contact", route: "/contact" },
      ],
    };
  },
  methods: {
    navigate(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify)

const opts = {
    theme: {
        dark: true,
        themes: {
            light: {
                primary: colors.blue.lighten2,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
           
            },
            dark: {
                primary: colors.yellow,
            },
        }
    },
}

export default new Vuetify(opts)

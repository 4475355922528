
<template>
  <div>
    <v-container>
      <ImageSlider />
      <OurMission class="mt-2" />
      <OurServices class="mt-2" />
      <ReviewSlider class="mt-5" />
    </v-container>
  </div>
</template>

<script>
import ImageSlider from "@/components/home/ImageSlider.vue";
import OurMission from "@/components/home/OurMission.vue";
import OurServices from "@/components/home/OurServices.vue";
import ReviewSlider from "@/components/home/Reviews.vue";

export default {
  components: {
    ImageSlider,
    ReviewSlider,
    OurMission,
    OurServices,
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <div class="hidden-xs-only">
      <div class="container">
        <v-carousel cycle show-arrows-on-hover height="1000px" transition="none">
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
            :src="image"
            style="filter: brightness(40%)"
          >
            <v-row class="fill-height" align="center" justify="center"> </v-row>
          </v-carousel-item>
        </v-carousel>
        <div class="centered">
          <div class="text-h4">
            <h2>Welcome to</h2>
            <h2 class="pt-4">HG Blocklayers Ltd</h2>
            <v-card-text class="mt-8 text-h6">
              We are a team of 18 + tradesmen who are vastly experienced in
              various aspects of construction. If it's a new build or a small
              extension our team can provide the tradesmen to complete your
              project on time and within budget. We take great pride in the work
              we do which is not only brick/blockwork but groundwork, finish
              Concrete , formwork/shuttering & steel erection. A selection of
              our tradesmen have over 40 years experience in the construction
              industry and have always upskilled by completing various courses
              to keep abreast of ever changing building regulations. We have
              recently added three apprentices to the team who are a valuable
              asset to Hg block-layer's and will gain valuable knowledge from
              our craftsmen over the next 4 years
            </v-card-text>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden-sm-and-up">
      <div class="container">
        <v-carousel cycle show-arrows-on-hover>
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
            :src="image"
            style="filter: brightness(40%)"
            ><p></p
          ></v-carousel-item>
        </v-carousel>
        <div class="centered">
          <div class="text-h6">
            <h4>Our Mission</h4>
            <v-card-text class="mt-8">
              "Our mission is to to exceed the expectations of clients,
              consultants and all the project team."
            </v-card-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    images: [
      "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-front.webp?alt=media&token=0418e2f1-afb3-4f21-b644-acd5c7ec0c60",
      "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fhouse-side.webp?alt=media&token=aceda1f8-741c-4d96-b30c-61b9c2b393d1",
      "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fworkers-planning.webp?alt=media&token=491a4c29-3b78-4632-bcff-8d5f0a8d6891",
      "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fariel-shot.webp?alt=media&token=30faf5c4-da6c-4893-9cb3-58cfef2a7a30",
    ],
  }),
};
</script>
<style scoped>
.slide-text {
  display: grid;
  place-items: center;
  filter: brightness(50%);
}
.slides {
  filter: brightness(100%) !important;
}
.container {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<template>
  <v-card>
    <v-card-title>Our Services</v-card-title>
    <v-row>
      <v-col cols="12" md="4">
        <v-icon color="primary" x-large>mdi-home</v-icon>
        <h3>Residential</h3>
        <v-card-text
          >Our team has completed hundreds of residential units to the highest
          standard on time whilst maintaining an exemplary health and safety
          record. We also specialize in brickwork and stone cladding.
        </v-card-text>
      </v-col>
      <v-col cols="12" md="4">
        <v-icon color="primary" x-large>mdi-domain</v-icon>
        <h3>Commercial</h3>
        <v-card-text
          >At HG Blocklayers Limited, we provide a full comprehensive service
          when it comes to commercial projects. We have built up years of
          experience in delivering premises that are specifically designed for
          the commercial sector. During every commercial project we maintain
          communication with our clients, ensuring everything is done to a high
          standard and to the satisfaction of the customer at all times.
        </v-card-text>
      </v-col>
      <v-col cols="12" md="4">
        <v-icon color="primary" x-large>mdi-pencil</v-icon>
        <h3>Private</h3>
        <v-card-text
          >HG Blocklayers limited has built and renovated homes throughout
          Sligo/Leitrim for many years. We have vast expertise in the private
          sector from initial groundwork, boundary walls both decorative and
          functional, building extensions and single houses all over the
          northwest region.Our highly experienced team of tradesmen and
          apprentices all work cohesively to achieve the client's specification
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {};
</script>
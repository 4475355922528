<template>
  <v-app>
    <v-main>
      <Header :email="email" :phone="phone" :openingHours="openingHours" />
      <div>
        <v-img
         width="250px"
         height="160px"
          class="center mt-2 mb-2"
          :src="logo"
        ></v-img>
      </div>
      <NavBar />
      <router-view></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import logo from "@/assets/HG-blocklayers-ltd.webp";
import Header from "@/components/Header";
import NavBar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  name: "App",
  components: {
    Header,
    NavBar,
    Footer,
  },
  data() {
    return {
      logo: logo,
      openingHours: "Mon - Fri: 9:00 - 18:30",
      email: "hgblocklayersltd@gmail.com",
      phone: "+353860625481",
    };
  },
};
</script>
<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background-color: yellow; */
}
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
  font-display: swap;
}

</style>

<template>
  <v-toolbar dark centered class="hidden-xs-only justify-center">
    <v-spacer></v-spacer>
    <v-icon class="mr-2 ml-20"> mdi-phone</v-icon><span> <a  style="  text-decoration: none;" v-bind:href="'tel:' + phone">{{ phone }}</a></span>
    <v-icon class="ml-10 mr-2">mdi-email</v-icon>
    <span><a v-bind:href="'mailto:' + email" style="  text-decoration: none;">{{email}}</a></span>
    <v-icon class="ml-10 mr-2">mdi-clock</v-icon
    ><span> {{ openingHours }} </span> <v-spacer />
  </v-toolbar>
</template>
<script>
export default {
  data() {
    return {
      appTitle: "HG Blocklayers Limited",
      sidebar: false,
    };
  },
  props: ["email", "phone", "openingHours"],
};
</script>
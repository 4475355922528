
<template>
  <div>
    projects
  </div>
</template>
  
<script>

export default {
    metaInfo: {
    title: "",
  }
}
</script>

<style scoped>

</style>

<template>
  <v-container>
    <v-card>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <video
            controls
            id="video"
            :width="videoWidth"
            height="300px"
            class="mt-2"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2FWhatsApp%20Video%202022-01-31%20at%202.45.48%20PM%20(1).mp4?alt=media&token=8d567927-249e-484f-927d-372618a9cd3b"
            />
          </video>
        </v-col>
        <v-col md="6" class="text-left">
          <v-card-title>About HG Blocklayers Ltd</v-card-title>
          <v-card-text class="text-left">
            We are a team of 18 + tradesmen who are vastly experienced in
            various aspects of construction. If it's a new build or a small
            extension our team can provide the tradesmen to complete your
            project on time and within budget. We take great pride in the work
            we do which is not only brick/blockwork but groundwork, finish
            Concrete , formwork/shuttering & steel erection. A selection of our
            tradesmen have over 40 years experience in the construction industry
            and have always upskilled by completing various courses to keep
            abreast of ever changing building regulations. We have recently
            added three apprentices to the team who are a valuable asset to Hg
            block-layer's and will gain valuable knowledge from our craftsmen
            over the next 4 years
          </v-card-text>

          <v-card-text>
            <b class="text-left"> - Managing Director, David Harkin </b>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="mt-2">
      <v-row no-gutters>
        <v-col md="6">
          <v-card-title>What we do</v-card-title>
          <v-card-text class="text-left">
            We specialise in all aspects of masonry works including brickwork,
            blockwork and stonecladding. Our team also has expertise in
            retaining walls and shuttering/formwork. We pride ourselves on
            excellence of craft with honesty & integrity to the fore when
            dealing with all of our clients and other subcontractors
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6" align="center" class="justify-center">
          <v-img
            class="mr-4 mt-2 mb-4"
            lazy-src="https://picsum.photos/id/11/10/6"
            max-height="350"
            max-width="500"
            src=   "https://firebasestorage.googleapis.com/v0/b/harkin-construction.appspot.com/o/gallary%2Fpath-and-spires.webp?alt=media&token=1a06d837-f1e5-4da2-8839-39c1e0780e8a"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row> </template
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "about",
  metaInfo: {
    title: "About Us",
  },
  computed: {
    videoWidth() {
      return screen.width > 400 ? "400px" : "300px";
    },
  },
};
</script>
<style scoped>
</style>